import { faSync } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useRef, useState } from 'react'

import { notiError } from '../../utils/notification'
import ImageCard from '../ImageCard'
import ImagePreview from '../ImagePreview'
import {
  ActionWrapper,
  CameraWrapper,
  ImageWrapper,
  StyledButton,
  SubTitle,
  Title,
} from './Styles'

let isFront = false
const getStream = () => {
  return navigator.mediaDevices.getUserMedia({
    video: {
      facingMode: { ideal: isFront ? 'user' : 'environment' },
      focusMode: { ideal: isFront ? undefined : 'continuous' },
    } as any,
  })
}

function ChimeraGenerator() {
  const [img, setImg] = useState<Blob | null>(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])
  const [capturable, setCapturable] = useState(false)
  const [switchable, setSwitchable] = useState(false)
  const [isSwitching, setIsSwitching] = useState(false)

  const videoRef = useRef<HTMLVideoElement>(null)
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const imgPickerRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    initCamera()
    // eslint-disable-next-line
  }, [])

  let alreadyInitDeviceId = false
  const initDeviceId = async () => {
    if (alreadyInitDeviceId) {
      return
    }
    alreadyInitDeviceId = true
    await navigator.mediaDevices.getUserMedia({ video: true })
    const devices = await navigator.mediaDevices.enumerateDevices()
    if (!devices.length) {
      return
    }
    let frontDeviceId = ''
    let backDeviceId = ''
    devices.forEach(d => {
      if (d.kind.toLowerCase().indexOf('video') < 0 || !d.label?.length) {
        return
      }
      const l = d.label.toLowerCase()
      const wide = l.indexOf('wide') >= 0
      const back = l.indexOf('back') >= 0 || l.indexOf('env') >= 0
      const front = l.indexOf('front') >= 0 || l.indexOf('user') >= 0
      if (back && (!wide || !backDeviceId)) {
        backDeviceId = d.deviceId
      }
      if (front && (!wide || !frontDeviceId)) {
        frontDeviceId = d.deviceId
      }
    })
    frontDeviceId = frontDeviceId || backDeviceId || devices[0].deviceId
    backDeviceId = backDeviceId || frontDeviceId || devices[0].deviceId
    setSwitchable(frontDeviceId !== backDeviceId)
  }

  const toggleCamera = async () => {
    if (isSwitching) {
      return
    }
    isFront = !isFront
    setIsSwitching(true)
    await initCamera()
    setIsSwitching(false)
  }

  const initCamera = async () => {
    setCapturable(false)
    try {
      await initDeviceId()
      if (!videoRef.current) {
        return
      }
      const v = videoRef.current
      const o = v.srcObject as MediaStream
      v.srcObject = null
      if (o && o.getTracks) {
        o.getTracks().forEach(t => t.stop())
      }
      v.setAttribute('autoplay', '')
      v.setAttribute('muted', '')
      v.setAttribute('playsinline', '')
      v.srcObject = await getStream()
      let timeoutId = 0
      const timeoutPromise = new Promise((resolve, reject) => {
        timeoutId = setTimeout(() => {
          isFront = !isFront
          initCamera()
          reject(
            new Error(
              'Can not access the camera, please try again or reload the browser if need',
            ),
          )
        }, 2000)
      })
      await Promise.race([v.play(), timeoutPromise])
      clearTimeout(timeoutId)
      setCapturable(true)
    } catch (err) {
      notiError(err)
      setCapturable(false)
    }
  }

  const handleOpenImgPickerDialog = () => {
    if (imgPickerRef.current) {
      imgPickerRef.current.click()
    }
  }

  const handleImgChange = ({ target }) => {
    if (!target.files.length) {
      return
    }
    setImg(target.files[0])
  }

  const handleCapture = () => {
    if (!canvasRef.current || !videoRef.current) {
      return
    }
    const c = canvasRef.current
    const ctx = c.getContext('2d')
    if (!ctx) {
      return
    }
    const v = videoRef.current
    const { videoWidth: sw, videoHeight: sh } = v
    console.error(sw, sh, v.width, v.height)
    const sr = sw / sh
    const dr = 361 / 400
    const dw = dr < sr ? sh * dr : sw
    const dh = dr < sr ? sh : sw / dr
    const sx = (sw - dw) / 2
    const sy = (sh - dh) / 2
    c.width = dw
    c.height = dh
    ctx.drawImage(v, sx, sy, dw, dh, 0, 0, dw, dh)
    c.toBlob(blob => {
      const file = new File([blob as Blob], 'image.png', {
        type: 'image/png',
      })
      setImg(file)
    })
  }

  const handleUpload = async () => {
    if (!img) {
      return
    }
    try {
      setLoading(true)
      const formData = new FormData()
      formData.append('image', img)

      const response = await fetch('/upload', {
        method: 'POST',
        body: formData,
      })
      const json = await response.json()

      if (json.error) {
        throw json
      }
      setData(json.data)
    } catch (err) {
      notiError(err)
    } finally {
      setLoading(false)
    }
  }

  const handleReset = () => {
    setData([])
    setImg(null)
  }

  const renderCaptureAndChooseFileActions = () => {
    const capturebtn = (
      <StyledButton disabled={!capturable} onClick={handleCapture} marginRight>
        Capture
      </StyledButton>
    )
    const switchbtn = (
      <StyledButton disabled={isSwitching} onClick={toggleCamera}>
        <FontAwesomeIcon icon={faSync} spin={isSwitching} />
      </StyledButton>
    )
    const filebtn = (
      <StyledButton outline onClick={handleOpenImgPickerDialog}>
        <input
          type='file'
          accept='image/*'
          style={{ display: 'none' }}
          ref={imgPickerRef}
          onChange={handleImgChange}
        />
        Choose File
      </StyledButton>
    )

    return switchable ? (
      <>
        <ActionWrapper>
          {capturebtn}
          {switchbtn}
        </ActionWrapper>
        <ActionWrapper>{filebtn}</ActionWrapper>
      </>
    ) : (
      <ActionWrapper>
        {capturebtn}
        {filebtn}
      </ActionWrapper>
    )
  }

  const renderUploadAndCancelActions = () => {
    return (
      <ActionWrapper>
        <StyledButton disabled={loading} onClick={handleUpload} marginRight>
          Upload
        </StyledButton>
        <StyledButton disabled={loading} danger onClick={() => setImg(null)}>
          Cancel
        </StyledButton>
      </ActionWrapper>
    )
  }

  const renderResetAction = () => (
    <ActionWrapper>
      <StyledButton onClick={handleReset}>Try Again</StyledButton>
    </ActionWrapper>
  )

  const renderActions = () => {
    if (!img) {
      return renderCaptureAndChooseFileActions()
    }
    if (img && !data.length) {
      return renderUploadAndCancelActions()
    }
    return renderResetAction()
  }

  const renderImageCard = () => {
    const camVisible = !img && !data.length
    const cam = (
      <CameraWrapper visible={camVisible}>
        <canvas ref={canvasRef} />
        <video width='361' height='400' ref={videoRef} onClick={toggleCamera} />
      </CameraWrapper>
    )
    if (img && !data.length) {
      return (
        <>
          {cam}
          <ImagePreview img={img} />
        </>
      )
    }
    if (camVisible) {
      return cam
    }

    return (
      <React.Fragment>
        {cam}
        <ImageCard src={data[0]} label='Subject' />
        <ImageCard src={data[1]} label='Subject Left Chimera' />
        <ImageCard src={data[2]} label='Subject Right Chimera' />
      </React.Fragment>
    )
  }

  return (
    <React.Fragment>
      <Title>Chimera Generator</Title>
      <ImageWrapper>{renderImageCard()}</ImageWrapper>
      {renderActions()}
      <SubTitle>
        Your headshot, for the most accurate results, should have the patient:
        <ul>
          <li>standing</li>
          <li>no glasses, hats or neck scarves</li>
          <li>non smiling </li>
          <li>eyes looking straight forward into the camera</li>
          <li>ears visible </li>
          <li>includes the neck</li>
        </ul>
      </SubTitle>
    </React.Fragment>
  )
}

export default ChimeraGenerator
