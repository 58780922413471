import styled, { css } from 'styled-components'

import mixins from '../../styles/mixins'

export const Title = styled.h2`
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.3px;
  font-size: 2.5em;
  margin-top: 5rem;
  margin-bottom: 0.9rem;
  font-weight: 600;
  color: #3d3d3d;

  @media (max-width: 575.98px) {
    margin-top: 15px;
  }

  @media (max-width: 767.98px) {
    font-size: 2em;
  }
`

export const SubTitle = styled.div`
  width: 100%;
  max-width: 500px;
  margin: 15px auto;
  line-height: 1.4;
  font-size: 1.2em;
  color: #5c5c5c;

  span {
    display: inline;
  }

  @media (max-width: 767.98px) {
    font-size: 1em;
  }

  @media (max-width: 575.98px) {
    font-size: 1.2em;
    line-height: 1.5;
    margin-bottom: 5px;
  }

  @media (min-width: 1400px) {
    span {
      display: block;
    }
  }
`

export const ActionWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
`

type ButtonProps = {
  outline?: boolean
  danger?: boolean
  marginRight?: boolean
}
export const StyledButton = styled('button')<ButtonProps>`
  border: 1px solid ${mixins.primaryColor};
  background: ${mixins.primaryColor};
  padding: 0.5em 1.6em;
  font-size: 1.1rem;
  color: #fff;
  border-radius: 4px;
  outline: none;
  cursor: pointer;
  transition: 0.2s;
  letter-spacing: 0.4px;
  position: relative;

  &:hover {
    border-color: #2e9fb4;
    background: #2e9fb4;
  }

  ${({ outline }) =>
    outline &&
    css`
      background: transparent;
      color: ${mixins.primaryColor};

      &:hover {
        color: #fff;
      }
    `}

  ${({ marginRight }) =>
    marginRight &&
    css`
      margin-right: 15px;
    `}

  ${({ danger }) =>
    danger &&
    css`
      background: ${mixins.dangerColor};
      border: 1px solid ${mixins.dangerColor};

      &:hover {
        background: #ff7875;
        border: 1px solid #ff7875;
      }
    `}

  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
`

export const ImageWrapper = styled.div`
  margin-top: 15px;
  display: grid;
  grid-template-columns: repeat(3, 361px);
  grid-gap: 1.2em;
  justify-content: center;

  @media (max-width: 1199.98px) {
    margin-top: 15px;
    grid-template-columns: repeat(3, 361px);
  }

  @media (max-width: 991.98px) {
    margin-top: 15px;
    grid-template-columns: repeat(1, 361px);
  }

  @media (max-width: 575.98px) {
    margin-top: 15px;
    grid-template-columns: repeat(1, 361px);
  }
`

export const CameraWrapper = styled('div')<{ visible: boolean }>`
  grid-column: 2 / 2;
  position: relative;
  font-size: 0 !important;
  line-height: 0 !important;

  ${({ visible }) =>
    css`
      display: ${visible ? 'block' : 'none'};
    `}

  canvas {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  video {
    height: 400px;
    width: 361px;
    border-radius: 4px;
    object-fit: cover;
  }

  @media (max-width: 991.98px) {
    grid-column: 1 / -1;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 180px;
    border-left: 1px solid red;
    animation: blinker 1s linear infinite;
  }
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }
`
