import React, { useEffect } from 'react'

import ImageCard from '../ImageCard'
import { StyledImagePreview } from './Styles'

function ImagePreview({ img }) {
  const blobUrl = URL.createObjectURL(img)

  useEffect(() => {
    setTimeout(() => {
      URL.revokeObjectURL(blobUrl)
    }, 1000)
  }, [blobUrl])

  return (
    <StyledImagePreview>
      <ImageCard src={blobUrl} />
    </StyledImagePreview>
  )
}

export default ImagePreview
