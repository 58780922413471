import React from 'react'

import { ImageWrapper } from './Styles'

interface IProps {
  label?: string
  src: any
}

function ImageCard({ label, src }: IProps) {
  return (
    <ImageWrapper>
      <div className='image' style={{ backgroundImage: `url(${src})` }} />
      <div className='label'>{label}</div>
    </ImageWrapper>
  )
}

export default ImageCard
