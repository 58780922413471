import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  background: #fff;
  height: 50px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #ececec;
  padding: 2em;
`

export const Logo = styled.img`
  height: 50px;
  margin: 0;
`
