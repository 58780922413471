import styled from 'styled-components'

export const ImageWrapper = styled.div`
  margin-bottom: 1rem;

  .image {
    height: 25rem;
    background: #f0f0f0;
    border-radius: 4px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  .label {
    margin-top: 0.7rem;
    color: #2a93a7;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.3px;
  }
`
