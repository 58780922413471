import React from 'react'

import logo from '../../assets/logo.png'
import { HeaderWrapper, Logo } from './Styles'

function Header() {
  return (
    <HeaderWrapper>
      <Logo src={logo} />
    </HeaderWrapper>
  )
}

export default Header
