import { createGlobalStyle } from 'styled-components'

import mixins from './mixins'

export default createGlobalStyle`
  body {
    padding: 0;
    margin: 0;
    background: ${mixins.bodyBackground};
    font-size: ${mixins.defaultFontSize};

    @media (max-width: 575.98px) {
      font-size: 12px;
    }
  }
`
