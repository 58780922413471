import 'antd/dist/antd.css'

import React from 'react'
import ReactDOM from 'react-dom'

import App from './components/App'
import GlobalStyle from './styles/globalStyle'

ReactDOM.render(
  <React.Fragment>
    <GlobalStyle />
    <App />
  </React.Fragment>,
  document.getElementById('root'),
)
