import React from 'react'

import { ContentWrapper } from '../styles/Layout'
import ChimeraGenerator from './ChimeraGenerator'
import Header from './Header'

function App() {
  return (
    <React.Fragment>
      <Header />
      <ContentWrapper>
        <ChimeraGenerator />
      </ContentWrapper>
    </React.Fragment>
  )
}

export default App
