import { notification } from 'antd'
import React from 'react'

export const notiError = err => {
  notification.destroy()
  notification.error({
    message: 'Error',
    description: (
      <>
        {err?.error || err?.message || `${err}`}
        {err?.errorDetail && (
          <strong>
            <br />
            <small>{err.errorDetail}</small>
          </strong>
        )}
      </>
    ),
  })
}
